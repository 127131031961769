import Vue from "vue";

function initialState() {
  return {
    step                    : "",
    currentTransition       : 'next',
    dados                   : {},
    JSON_FORM               : {},
    acao                    : null,
    tipo_cliente            : null,
    botoes                  : [],
    cod_opor                : null,
    cod_empreendvenda       : null,
    cod_orcamento           : null,
    contato_selecionado     : [],
    pessoa_selecionado      : [],
    corretor_selecionado    : [],
    prospeccao_selecionada  : {},
    dialog                  : false,
    dialog_novo_lead        : false,
    vencimento_dt           : null,
    vencimento_dt_horas     : null,
    key_valor               : 1
  }
}

var vm_store_ModalNegociacao = new Vue({
  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },
  }

})

export default vm_store_ModalNegociacao

