<template>
  <div id="ModalDadosBancariosCadastro">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="text-white title-page body-1">Cadastro de Dados Bancários</span>
          <v-btn
          @click="store_Pessoa.dialogDadosBancariosCadastro = false; fechar()"
          icon
          dark
          color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form
                ref="form_banco"
                v-model="valid_banco">
                <v-select
                  v-show="!this.dados_modal_bancario?.cod_pessoa_conta"
                  label="Tipo"
                  v-model="dados.pessoa_conta_tipo"
                  light
                  filled
                  dense
                  :rules="tipoRules"
                  outlined
                  @change="setDescricao()"
                  item-value="tipo"
                  item-text="name"
                  background-color="#FFF"
                  :items="opcoesTipo"
                  return-object/>

                <div v-if="dados?.pessoa_conta_tipo?.tipo === 'D'">
                  <v-text-field
                    v-model="dados.pessoa_conta_descricao"
                    class="mt-n2"
                    light
                    background-color="#FFF"
                    outlined
                    label="Descrição"
                    placeholder="Descrição"
                    filled
                    readonly
                    dense/>

                  <VuetifyMoney
                    v-model="dados.pessoa_conta_saldo_inicial"
                    type="tel"
                    filled
                    dense
                    outlined
                    background-color="#FFF"
                    placeholder="Saldo Inicial"
                    label="Saldo Inicial"/>
               
                </div>

                <div v-if="dados?.pessoa_conta_tipo?.tipo === 'CC'">
                  <v-text-field
                    v-model="dados.pessoa_conta_bandeira"
                    light
                    background-color="#FFF"
                    outlined
                    label="Bandeira"
                    placeholder="Bandeira"
                    filled
                    :rules="bandeiraRules"
                    required
                    maxlength="80"
                    dense/>
                     
                  <v-text-field
                    v-model="dados.pessoa_conta_numero"
                    light
                    outlined
                    background-color="#FFF"
                    label="Nº Cartão"
                    placeholder="Nº Cartão"
                    filled
                    clearable
                    maxlength="20"
                    required
                    dense
                    :rules="cartaoRules"
                    ></v-text-field>

                  <v-text-field
                    v-model="dados.pessoa_conta_titular"
                    light
                    background-color="#FFF"
                    outlined
                    label="Titular"
                    placeholder="Titular"
                    filled
                    required
                    :rules="nomeRules"
                    dense/> 

                  <VuetifyMoney
                    v-model="dados.pessoa_conta_saldo_inicial"
                    type="tel"
                    filled
                    dense
                    outlined
                    background-color="#FFF"
                    placeholder="Saldo Inicial"
                    label="Saldo Inicial"/>
                </div>

                <div v-if="dados?.pessoa_conta_tipo?.tipo === 'B'">

                   <v-select
                    v-model="selectedBanco"
                    item-value="cod_banco"
                    item-text="banco_nome"
                    @change="changeRoute"
                    @input="changeRoute"
                    label="Banco"
                    light
                    outlined
                    filled
                    dense
                    background-color="#FFF"
                    :items="dados_modal_bancario_bancos"
                    :rules="bancoRules"
                    required
                    return-object />
   
                    <div
                      style="display:flex; justify-content: center; align-items: flex-start;"
                      class="mt-n2">
                      <v-select
                        label="Agência"
                        v-model="selectedAgencia"
                        :items="selectedBanco.banco_agencia"
                        item-value="cod_agencia"
                        item-text="agencia_numero"
                        light
                        outlined
                        filled
                        dense
                        background-color="#FFF"
                        :rules="agenciaRules"
                        required
                        return-object />
                      <v-icon
                        @click="abrirModal(dados)"
                        :disabled="!selectedBanco?.cod_banco"
                        class="ml-2"
                        style="margin-top: 15px"
                        color="primary">
                        mdi-magnify-plus
                      </v-icon>
                    </div>

                    <v-text-field
                      v-model="dados.pessoa_conta_numero"
                      class="mt-n2"
                      light
                      background-color="#FFF"
                      label="Conta"
                      placeholder="Conta"
                      filled
                      outlined
                      required
                      dense
                      :rules="contaRules"
                      ></v-text-field>
     
                    <v-text-field
                      v-model="dados.pessoa_conta_titular"
                      class="mt-n2"
                      light
                      background-color="#FFF"
                      label="Titular"
                      placeholder="Titular"
                      filled
                      outlined
                      required
                      dense
                      ></v-text-field>
    
                    <v-text-field
                    v-mask="MASK_CPFCNPJ"
                      v-model="dados.pessoa_conta_titular_cpf"
                      class="mt-n2"
                      light
                      background-color="#FFF"
                      label="CPF/CNPJ"
                      placeholder="CPF/CNPJ"
                      filled
                      outlined
                      required
                      dense
                      ></v-text-field>

                  <VuetifyMoney
                    v-model="dados.pessoa_conta_saldo_inicial"
                    filled
                    dense
                    outlined
                    background-color="#FFF"
                    placeholder="Saldo Inicial"
                    label="Saldo Inicial"
                    :clearable="false"/>

                  <DatePicker
                      v-model="dados.pessoa_conta_saldo_inicial_dt"
                      label="Data saldo inicial"
                      placeholder="Data saldo inicial"
                      outlined
                      backgroundColor="#FFF"
                      dense />
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Pessoa.dialogDadosBancariosCadastro = false; fechar()"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid_banco"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
        <!-- DIALOGO AGENCIA -------------------------------------------------------->
      <v-container v-if="dialogAgencia" >      
        <v-dialog
          v-model="dialogAgencia"
          scrollable
          max-width="500px"
          max-height="600px">
          
              <AgenciaConsultaModalMobile
              @close="close"
              @agenciaSelecionada="emitFilho"
              class="mt-n2"
              :banco_ = "dados.banco"
              :editar_="true"
              :objectBancoAux = objectBancoAux
              :agencia_selecionada.sync="agencia_selecionada"/>
          
        </v-dialog>
      </v-container>

    <!-- FIM DIALOGO AGENCIA ---------------------------------------------------->
    </v-card>
  </div>
</template>

<script>
import { API } from "../../services/API";
import { COR_PRINCIPAL, MASK_CPFCNPJ, COR_SECUNDARIA } from "../../services/constantes";
import { CpfCnpjIsValid } from "../../services/funcoes";
import store_Pessoa from "./store_Pessoa";
import store_site from "../../store/store_site"
import AgenciaConsultaModalMobile from "../CadastroBasico/AgenciaConsultaModalMobile.vue"
import VuetifyMoney from "../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import DatePicker from "../ComponentesGlobais/Date-Picker/date-picker.vue";


export default {
  name: "ModalDadosBancariosCadastro",

  props: [
    'dados_modal_bancario',
    'dados_modal_bancario_bancos',
    'contas',
    'nomeTitular'
  ],

  components: {
    AgenciaConsultaModalMobile,
    VuetifyMoney,
    DatePicker,
  },

  data() {
    return {

      store_Pessoa      : store_Pessoa,
      store_site        : store_site,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      MASK_CPFCNPJ      : MASK_CPFCNPJ,

      selectedBanco       : {cod_agencia : null},
      selectedAgencia     : {},
      dialogAgencia       : null,
      objectBancoAux      : null,
      agencia_selecionada : null,
      agenciasFilho       : null,

      opcoesTipo : [
        { name: "Cartão de Crédito", tipo: "CC" },
        { name: "Banco", tipo: "B" },
        { name: "Dinheiro", tipo: "D" },
      ],

      valid: false,
      valid_banco: false,
      // form: false,

      tipoRules: [
        (value) => !!value  || 'O Tipo é obrigatório'
      ],
      bancoRules: [
        (value) => !!value  || 'O Banco é obrigatório'
      ],
      agenciaRules: [
        (value) => !!value || 'A Agência é obrigatória'
      ],
      bandeiraRules: [
        (value) => !!value || 'A Bandeira é obrigatória'
      ],
      cartaoRules: [
        (value) => !!value || 'O Nº Cartão é obrigatório'
      ],
    
      contaRules: [
        (value) => !!value || "A conta é obrigatória",
        (value) =>
          (value && value.length >= 4) ||
          "A conta deve ter no mínimo 4 caracteres",
        (value) =>
          (value && value.length <= 12) ||
          "A conta deve ter menos do que 12 caracteres",
      ],
      nomeRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O número deve ter menos do que 60 caracteres",
      ],
      cpf_cnpjRules: [
        (value) => !!value || "O cpf/cnpj é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O cpf/cnpj não é válido",
      ],
    };
  },

  created(){
    if(!!this.dados_modal_bancario?.cod_pessoa_conta){
      let tipo = this.dados_modal_bancario.pessoa_conta_tipo
      this.dados_modal_bancario.pessoa_conta_tipo = {}
      this.dados_modal_bancario.pessoa_conta_tipo.tipo = tipo
      
      this.setDescricao()

      // console.log("🚀 ~ file: ModalDadosBancariosCadastro.vue:370 ~ created ~ this.selectedAgencia:", this.selectedAgencia)
      this.selectedAgencia.cod_agencia = this.dados_modal_bancario?.cod_pessoa_conta_agencia
      this.selectedAgencia.agencia_numero = this.dados_modal_bancario?.pessoa_conta_agencia?.agencia_numero
    }
  },

  mounted() {
    const tipoEncontrado = this.contas.some(conta => conta?.pessoa_conta_tipo === 'D');
    if(tipoEncontrado){
      const index = this.opcoesTipo.findIndex(opcao => opcao?.tipo === "D");

      if (index !== -1) {
        this.opcoesTipo.splice(index, 1);
      }
    }
    // Montou este componente pela primeira vez. montar dados do banco selecionado, senão, somente abrir os bancos
    if (this.dados_modal_bancario.acao == 'E' && this.dados_modal_bancario.cod_banco) {
      const lo_Banco = this.dados_modal_bancario_bancos.filter((e) => e.cod_banco == this.dados_modal_bancario.cod_banco);
      if (lo_Banco && lo_Banco.length > 0) {
        this.selectedBanco = lo_Banco[0];
      }
    }
  },

  watch: {
    // Ouvindo o Modal quando ele é acionado novamente e montando dados do banco selecionado e agencia
    "store_Pessoa.dialogDadosBancariosCadastro": function(val) {
      if(val) {
        const lo_Banco = this.dados_modal_bancario_bancos.filter((e) => e.cod_banco == this.dados.banco.cod_banco);
        if (lo_Banco && lo_Banco.length > 0) {
          this.selectedBanco = lo_Banco[0];
          // this.dados.cod_pessoa_conta_agencia = this.dados_modal_bancario.cod_pessoa_conta_agencia;
        }
      }
    },
  },

  computed:{
    // Retornando os dados a serem editados, que vem do componente Pai
    dados() {
      return this.dados_modal_bancario
    },
  },

  methods: {
    fechar(){
      this.$emit('update:dialogModalDadosBancariosCadastro', false);
    },

    setDescricao(){
      if(this.dados?.pessoa_conta_tipo?.tipo === 'D'){
        this.dados.pessoa_conta_descricao = 'CAIXA INTERNO'
      }
    },
    atualizaAgencias(){

    },

    async emitFilho(dados_emit){
      const resposta = await API.get("banco", { params: {cod_banco : dados_emit.cod_banco }});
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          // this.array_agencia = resposta.data.result[0].banco_agencia
          this.selectedBanco.banco_agencia = resposta.data.result[0].banco_agencia
          this.selectedAgencia = dados_emit
        }
      }
      this.$nextTick(() => {
        this.dados.cod_pessoa_conta_agencia = this.agencia_selecionada.cod_agencia
        this.close()
      })
    },
    close() {
      this.dialogAgencia = false
    },

    abrirModal(cod_banco){
      if (!!cod_banco.pessoa_conta_agencia?.banco_agencia) {
        this.objectBancoAux = cod_banco.pessoa_conta_agencia.banco_agencia
      }
      else{
        this.objectBancoAux = this.selectedBanco
      }
      this.dialogAgencia = true
    },
    // Mudou Banco no Select, então atribuir o banco selecionado
    changeRoute: function(e){
      this.selectedAgencia = {}
      this.selectedBanco = e
      // console.log("🚀 ~ file: ModalDadosBancariosCadastro.vue:450 ~ this.selectedBanco:", this.selectedBanco)
    },

    async validate() {
      const lb_valido = this.$refs.form_banco.validate();
      if (lb_valido) {
        // Gravando dados ----------------
        if (this.dados) {
          let lo_dados = {}
          let ls_tipo                = this.dados.pessoa_conta_tipo.tipo
          lo_dados.pessoa_conta_tipo = ls_tipo
          lo_dados.cod_pessoa        = this.dados.cod_pessoa
          lo_dados.pessoa_conta_saldo_inicial = this.dados.pessoa_conta_saldo_inicial || 0
          lo_dados.acao = this.dados.acao

          if(!!this.dados.cod_pessoa_conta){
            lo_dados.cod_pessoa_conta = this.dados.cod_pessoa_conta
          }
         
          if(this.dados?.pessoa_conta_tipo?.tipo === 'B'){
            if(!this.selectedBanco.cod_banco){
              this.store_site.alert_cor       = "#FF0000";
              this.store_site.alert_timeout   = 10000;
              this.store_site.alert_msg       = 'O campo Banco é obrigatório';
              this.store_site.alert           = true;
              return
            }
            if(!this.selectedAgencia.cod_agencia){
              this.store_site.alert_cor       = "#FF0000";
              this.store_site.alert_timeout   = 10000;
              this.store_site.alert_msg       = 'O campo Agência é obrigatório';
              this.store_site.alert           = true;
              return
            }
            lo_dados.cod_banco                     = this.selectedBanco.cod_banco;
            lo_dados.cod_pessoa_conta_agencia      = this.selectedAgencia.cod_agencia
            lo_dados.pessoa_conta_numero           = this.dados.pessoa_conta_numero
            lo_dados.pessoa_conta_titular          = this.dados.pessoa_conta_titular
            lo_dados.pessoa_conta_titular_cpf      = this.dados.pessoa_conta_titular_cpf
            lo_dados.pessoa_conta_descricao        = `${this.selectedBanco.banco_cod_compensacao} - ${this.selectedBanco.banco_nome} - ${this.selectedAgencia.agencia_numero} - ${this.dados.pessoa_conta_numero} - ${this.nomeTitular}`
            lo_dados.pessoa_conta_descricao        = lo_dados.pessoa_conta_descricao.substr(0, 80)
            lo_dados.pessoa_conta_saldo_inicial_dt = this.dados.pessoa_conta_saldo_inicial_dt
            // console.log("lo_dados:", lo_dados)
          }else if(this.dados?.pessoa_conta_tipo?.tipo === 'D'){
            lo_dados.pessoa_conta_descricao     = this.dados.pessoa_conta_descricao

          }else if(this.dados?.pessoa_conta_tipo?.tipo === 'CC'){
            lo_dados.pessoa_conta_bandeira      = this.dados.pessoa_conta_bandeira
            lo_dados.pessoa_conta_numero        = this.dados.pessoa_conta_numero
            lo_dados.pessoa_conta_titular       = this.dados.pessoa_conta_titular
            lo_dados.pessoa_conta_descricao     = `${this.dados.pessoa_conta_bandeira} - ${this.dados.pessoa_conta_numero} - ${this.dados.pessoa_conta_titular}`
            lo_dados.pessoa_conta_descricao     = lo_dados.pessoa_conta_descricao.substr(0, 80)
          }
          // console.log("🚀 ~ file: ModalDadosBancariosCadastro.vue:468 ~ validate ~ this.dados:", lo_dados)
          // return

          let ls_Res = null;
          if (this.store_Pessoa.acao == 'C') {
            ls_Res = await API.post(`pessoa_conta`, JSON.stringify(lo_dados));
            this.store_Pessoa.dialogDadosBancariosCadastro = false;
            this.fechar()

            if(lo_dados.pessoa_conta_tipo == 'B' || this.dados?.pessoa_conta_tipo?.tipo === 'CC'){
              const objetoComMaiorCodPessoaConta = ls_Res?.data?.result?.data.reduce((maiorObjeto, objetoAtual) => {
                  if (!maiorObjeto || objetoAtual.cod_pessoa_conta > maiorObjeto.cod_pessoa_conta) {
                      return objetoAtual;
                  } else {
                      return maiorObjeto;
                  }
              }, null);

              if (objetoComMaiorCodPessoaConta) {
                  lo_dados.cod_pessoa_conta = objetoComMaiorCodPessoaConta.cod_pessoa_conta
              } 
              this.$emit('atualizaConta', {...lo_dados})
            }

            if (ls_Res.status == 200) {
              this.store_Pessoa.pessoa_edicao.pessoa_contas = ls_Res.data.result.data
              this.store_site.alert_cor       = "#00A000";
              this.store_site.alert_timeout   = 1500;
              this.store_site.alert_msg       = ls_Res.data.result.result.trim();
              this.store_site.alert           = true;

            } else {
              this.store_site.alert_cor       = "#FF0000";
              this.store_site.alert_timeout   = 10000;
              this.store_site.alert_msg       = ls_Res.data.errors.trim();
              this.store_site.alert           = true;
            }

          }
        }

      }
      let lo_dados = {}

    },

  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  overflow-y: auto;
}

.btn {
  width: 100px;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
    width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
    height: 150px;
} 
</style>